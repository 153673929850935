import { post } from '@/utils/request'

export function getList(data) {
    return post('admin/order/list', data)
}
export function getInfo(data) {
    return post('admin/order/info', data)
}
export function printAgain(data) {
    return post('admin/order/print', data)
}
export function PostOrderFlowList(data) {
    return post('admin/Order/orderFlowList', data)
}
export function PostOrderTransactionDetailsListExport(data) {
    return post('admin/Order/orderTransactionDetailsListExport', data)
}
export function PostOrderFlowListExport(data) {
    return post('admin/Order/orderFlowListExport', data)
}
export function PostOrderRefund(data) {
    return post('/admin/Order/refund', data)
}